/* eslint-disable object-curly-newline */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from "react-helmet";
import HttpHelpers from "./ymc-sdk/services/helpers";
import store from "./ymc-sdk/redux/storeConfig";
import localStorageProvider from "./localStorageProvider";
import Home from "./views/home";
import PublicLayout from "./layouts/public-layout";
import Login from "./views/login";
import CreateOffer from "./views/createOffer";
import ClientContacts from "./views/createOffer/ClientContacts/ClientContacts";
import OfferDetailsPage from "./views/OfferDetails";
// import CompleteOfferDetails from './views/OfferDetails/CompleteOfferDetails';
import OfferSummary from "./views/offerSummary/OfferSummary";
import PriceOfferPage from "./views/priceOfferSearch/PriceOfferPage";
import EditOffer from "./views/priceOfferSearch/Edit offer";
import History from "./views/History";
import Config from "./views/Configuration";
import NewClient from "./views/addEditClient/newClient";
import EditClient from "./views/addEditClient/editClient";
import ClientSearch from "./views/clientSearch";
import Client from "./views/client";
import DieselInvoiceForm from "./views/dieselInvoice/invoiceForm";
import QRScanner from "./views/dieselInvoice/qrScanner";
import DieselInvoiceSearch from "./views/dieselInvoiceSearch";
import UserSettings from "./views/userSettings";
import RoleActionsSetting from "./views/userSettings/roleActionsSetting";
import Unauthorized from "./views/unauthorized";
import ProtectedLayout from "./layouts/protectedLayout";
import { getUserData, logOut } from "./ymc-sdk/redux/actions/auth.actions";
import Loading from "./components/Loading";
import DieselInvoiceHistory from "./views/dieselInvoiceHistory";
import RolePageSettings from "./views/userSettings/rolePageSettings";

HttpHelpers.setBaseUrl(process.env.REACT_APP_API_URL);

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  document.querySelector("html").dir = i18n.dir();
  const [loading, setLoading] = useState(true);
  const handleLogout = () => {
    dispatch(logOut());
    // navigate("/");
    // navigate("/login");
    return <Navigate to="/login" />;
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    localStorageProvider.get("locale").then((lng) => {
      let locale;
      if (!lng) {
        locale = "en";
      } else {
        locale = lng;
      }

      if (!lng) localStorageProvider.set("locale", locale);
      i18n.changeLanguage(locale);
      document.querySelector("html").dir = i18n.dir();
      document.querySelector("html").lang = locale;

      document.querySelector("#bootstrap-ltr").disabled = locale === "ar";
      document.querySelector("#bootstrap-rtl").disabled = locale === "en";
    });
    const userId = localStorage.getItem("UserId");
    const token = localStorage.getItem("Token");
    // && userId !== "null"
    // console.log("mmmmmmmmmmmmmmmmmmmm");
    if (userId && token && userId !== "null") {
      dispatch(getUserData(userId))
        .then((response) => {
          // console.log(response);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          handleLogout();
        });
    } else {
      setLoading(false);
      handleLogout();
    }
  }, []);
  if (loading) {
    return (
      <div className="auth-modal body-style mt-3 px-5 py-3">
        {loading && <Loading />}
      </div>
    );
  }

  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedLayout pageRoute="" />}>
            <Route path="/" element={<Home />} />
          </Route>
          {/* <Route element={<PublicLayout />}>
            <Route path="/CreateFirstTable/:id" element={<ClientContacts />} />
          </Route> */}
          {/* <Route element={<PublicLayout />}>
            <Route path="/SignUp" element={<SignUp />} />
          </Route> */}
          <Route element={<ProtectedLayout pageRoute="createOffer" />}>
            <Route path="/createOffer" element={<CreateOffer />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="offerDetails" />}>
            <Route
              path="/offerDetails/:offerId"
              element={<OfferDetailsPage />}
            />
          </Route>
          {/* <Route element={<PublicLayout />}>
            <Route path="/editOffer/:offerId" element={<OfferDetailsPage />} />
          </Route> */}
          <Route element={<ProtectedLayout pageRoute="history" />}>
            <Route path="/history/:offerId" element={<History />} />
          </Route>
          {/* <Route element={<PublicLayout />}>
            <Route path="/CompleteOfferDetails/:offerId" element={<CompleteOfferDetails />} />
          </Route> */}
          <Route element={<ProtectedLayout pageRoute="offerSummary" />}>
            <Route path="/offerSummary/:offerId" element={<OfferSummary />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="offerSearch" />}>
            <Route path="/offerSearch" element={<PriceOfferPage />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="config" />}>
            <Route path="/config" element={<Config />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="editPriceOffer" />}>
            <Route path="/editPriceOffer/:offerId" element={<EditOffer />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="clients" />}>
            <Route path="/clients/:clientId" element={<Client />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="addNewClient" />}>
            <Route path="/addNewClient" element={<NewClient />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="editClient" />}>
            <Route path="/editClient/:clientId" element={<EditClient />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="clientSearch" />}>
            <Route path="/clientSearch" element={<ClientSearch />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="dieselInvoice" />}>
            <Route path="/dieselInvoice" element={<DieselInvoiceForm mode="create" />} />
          </Route>
          {/* <Route element={<ProtectedLayout pageRoute="editDieselInvoice" />}>
            <Route path="/editDieselInvoice/:invoiceId" element={<DieselInvoiceForm />} />
          </Route> */}
          <Route element={<ProtectedLayout pageRoute="editDieselInvoice" />}>
            <Route path="/editDieselInvoice/:invoiceId" element={<DieselInvoiceForm mode="edit" />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="invoiceHistory" />}>
            <Route path="/invoiceHistory/:invoiceId" element={<DieselInvoiceHistory />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="dieselScanner" />}>
            <Route path="/dieselScanner" element={<QRScanner />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="dieselInvoiceSearch" />}>
            <Route
              path="/dieselInvoiceSearch"
              element={<DieselInvoiceSearch />}
            />
          </Route>
          <Route element={<ProtectedLayout pageRoute="userSettings" />}>
            <Route path="/userSettings" element={<UserSettings />} />
          </Route>
          <Route element={<ProtectedLayout pageRoute="editRoleActions" />}>
            <Route
              path="/editRoleActions/:roleId"
              element={<RoleActionsSetting />}
            />
          </Route>
          <Route element={<ProtectedLayout pageRoute="editRoleActions" />}>
            <Route
              path="/rolePageSettings/:pageId/:roleId"
              element={<RolePageSettings />}
            />
          </Route>
          {/* <Route element={<PublicLayout />}>
            <Route path="/diesalClients" element={<DiesalClients />} />
          </Route> */}
          <Route element={<PublicLayout />}>
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
