import * as helpers from "./helpers";
import SharedApiEndpoints from "./shared.api";
import AuthApiEndpoints from "./auth.api";
import OfferApiEndpoints from "./offer.api";
import ClientsApiEndpoints from "./client.api";
import SearchOfferApiEndpoints from "./searchOffer.api";
import PaymentApiEndpoints from "./terms.api";
import DiesalClientsApiEndpoints from "./diesalClients.api";
import LookupDataApiEndpoints from "./lookupData.api";
import UploadFilesEndPoints from "./uploadFiles.api";
import RoleActionsEndPoints from "./roleActions.api";
import ClientSearchApiEndpoints from "./clientSearch.api";
import HomeApiEndpoints from "./home.api";
import RolePageSettingsEndPoints from "./rolePageSettings.api";

const Api = {
  ...helpers,
  home: HomeApiEndpoints,
  shared: SharedApiEndpoints,
  auth: AuthApiEndpoints,
  offer: OfferApiEndpoints,
  clients: ClientsApiEndpoints,
  clientSearch: ClientSearchApiEndpoints,
  search: SearchOfferApiEndpoints,
  config: PaymentApiEndpoints,
  diesalClients: DiesalClientsApiEndpoints,
  lookupData: LookupDataApiEndpoints,
  uploadFiles: UploadFilesEndPoints,
  roleActions: RoleActionsEndPoints,
  rolePageSettings: RolePageSettingsEndPoints,
};

export default Api;
