import HttpHelpers from "./helpers";

const RolePageSettingsEndPoints = {
  getDateRangeByPageIdAndRoleId: (params) => {
    return HttpHelpers.authenticatedAxios
      .get(`RolePageDateRange/GetRolePageDateRangeByRoleIdAndPageId`, { params })
      .then((response) => {
        return response.data;
      });
  },
  createDateRange: (data, params) => {
    return HttpHelpers.authenticatedAxios
      .post(`RolePageDateRange`, data, { params })
      .then((response) => {
        return response.data;
      });
  },
};

export default RolePageSettingsEndPoints;
