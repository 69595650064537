/* eslint-disable operator-linebreak */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/prop-types */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Label, Row, Button, Form } from "reactstrap";
import { components } from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";
import "../../dieselInvoiceSearch/datePickerCustomStyle.css";
import Loading from "../../../components/Loading";
import AuthApiEndpoints from "../../../ymc-sdk/services/auth.api";
import Api from "../../../ymc-sdk/services";
import ModalMultiSelect from "./ModalMultiSelect";
import ClientsApiEndpoints from "../../../ymc-sdk/services/client.api";

function CheckboxOption(props) {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{ marginRight: 10 }}
      />
      {props.label}
    </components.Option>
  );
}

function RolePageSettings({
  rolePriceOfferSetting,
}) {
  const { i18n, t } = useTranslation();
  const { roleId, pageId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [userId, setUserId] = useState([]);
  const [selectClientsYmc, setSelectClientsYmc] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectClientsSystem9, setSelectClientsSystem9] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [mode, setMode] = useState("create");

  const { data: allPages, isLoading } = useQuery(
    ["allPages"],
    Api.roleActions.getAllPages,
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => data,
    },
  );
  const { data: adminUsers } = useQuery(
    ["admin users"],
    () => AuthApiEndpoints.getSystemUsers(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    },
  );
  const { data: clientsYMC } = useQuery(
    ["Clients YMC"],
    () => ClientsApiEndpoints.getClientsYMC(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    },
  );
  const { data: clients9 } = useQuery(
    ["Clients 9"],
    () => ClientsApiEndpoints.getClientsSystem9(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    },
  );
  const handleStartDateChange = (date) => {
    if (endDate && date >= endDate) {
      setEndDate(null);
    }
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if (startDate && date <= startDate) {
      setStartDate(null);
    }
    setEndDate(date);
  };

  // Get min/max times for same day selections
  const getTimeRanges = (currentDate, otherDate, isStart) => {
    if (
      !currentDate ||
      !otherDate ||
      currentDate.getDate() !== otherDate.getDate()
    ) {
      return {
        minTime: new Date(currentDate).setHours(0, 0, 0, 0),
        maxTime: new Date(currentDate).setHours(23, 59, 59, 999),
      };
    }

    if (isStart) {
      return {
        minTime: new Date(currentDate).setHours(0, 0, 0, 0),
        maxTime: new Date(otherDate).getTime(),
      };
    }

    return {
      minTime: new Date(otherDate).getTime(),
      maxTime: new Date(currentDate).setHours(23, 59, 59, 999),
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = {
        start: startDate,
        end: endDate,
        roleId,
        pageId,
        createdBy: localStorage.getItem("UserId"),
        lastUserModifyId: localStorage.getItem("UserId"),
      };

      // Add your API call here
      // await AuthApiEndpoints.updateRoleSettings(formData);
      Api.rolePageSettings
        .createDateRange(formData, { UserId: localStorage.getItem("UserId") })
        .then((res) => {
          // console.log(res);
          setIsSubmitting(false);
          toast.success(t("shared.editedSuccessfully"));
        })
        .catch((err) => {
          // console.log(err);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    } catch (error) {
      toast.error(t("userSettings.roleUpdateError"));
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    // console.log(adminUsers);
    setLoading(true);
    Api.rolePageSettings
      .getDateRangeByPageIdAndRoleId({
        PageId: pageId,
        RoleId: roleId,
      })
      .then((res) => {
        if (res.data) {
          setStartDate(new Date(res.data.start));
          setEndDate(new Date(res.data.end));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading || isloading) {
    return <Loading />;
  }

  return (
    <div className="auth-modal body-style mt-3 px-5 py-3">
      <Helmet>
        <title>{`YMC Group | ${t("userSettings.roleSettings")}`}</title>
      </Helmet>

      <div className="text-center mb-4">
        <h1 className="mb-4">
          {allPages.find((page) => page.RoutingName === "offerSearch").NameEn}
        </h1>
      </div>

      <Form onSubmit={handleSubmit}>
        <Row className="gx-5 gy-3">
          <Col md={6}>
            <Label> {t("search.startDate")} </Label>
            <DatePicker
              className="w-100 react-datepicker__input-container"
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText={t("dieselInvoice.startDate")}
              showTimeSelect
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              maxDate={endDate}
              {...(startDate &&
                endDate &&
                getTimeRanges(startDate, endDate, true))}
            />
          </Col>
          <Col md={6}>
            <Label> {t("search.endDate")} </Label>
            <DatePicker
              className="w-100 react-datepicker__input-container"
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText={t("dieselInvoice.endDate")}
              showTimeSelect
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              minDate={startDate}
              {...(startDate &&
                endDate &&
                getTimeRanges(endDate, startDate, false))}
            />
          </Col>

          <Col xs={12} className="text-center mt-5">
            <Button
              type="submit"
              size="md"
              disabled={isSubmitting}
              className="main-button primary d-block btn m-auto mt-5"
            >
              {isSubmitting ? (
                <span>
                  <i className="fas fa-spinner fa-spin me-2" />
                  {t("common.submitting")}
                </span>
              ) : (
                t("userSettings.send")
              )}
            </Button>
          </Col>
          <Col md={6} className="text-center mt-3">
            <ModalMultiSelect
              label="Select Options"
              options={adminUsers || []}
              selectedItems={selectedUsers}
              setSelectedItems={setSelectedUsers}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
              placeholder="Click to select options..."
            />
          </Col>
          <Col md={6} className="text-center mt-3">
            <ModalMultiSelect
              label="Select Options"
              options={clientsYMC || []}
              selectedItems={selectClientsYmc}
              setSelectedItems={setSelectClientsYmc}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
              placeholder="Click to select options..."
            />
          </Col>
          <Col md={6} className="text-center mt-3">
            <ModalMultiSelect
              label="Select Options"
              options={clients9 || []}
              selectedItems={selectClientsSystem9}
              setSelectedItems={setSelectClientsSystem9}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
              placeholder="Click to select options..."
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default RolePageSettings;
