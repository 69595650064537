import React, { useState, useRef, useEffect } from 'react';
import './ModalMultiSelect.css';

function ModalMultiSelect({
  label,
  options,
  selectedItems,
  setSelectedItems,
  getOptionLabel,
  getOptionValue,
  placeholder,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [localSelectedItems, setLocalSelectedItems] = useState([]);
  const modalRef = useRef(null);
  const searchInputRef = useRef(null);

  // Initialize local state with parent state when modal opens
  useEffect(() => {
    if (isOpen) {
      setLocalSelectedItems(selectedItems);
    }
  }, [isOpen, selectedItems]);

  // Handle clicking outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      // Focus the search input when modal opens
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const openModal = () => {
    setIsOpen(true);
    setSearchTerm('');
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const applySelection = () => {
    setSelectedItems(localSelectedItems); // Update parent state
    closeModal();
  };

  const cancelSelection = () => {
    closeModal();
  };

  const toggleOption = (option) => {
    const value = getOptionValue(option);
    const isSelected = localSelectedItems.some(
      (item) => getOptionValue(item) === value,
    );

    if (isSelected) {
      setLocalSelectedItems(
        localSelectedItems.filter((item) => getOptionValue(item) !== value),
      );
    } else {
      setLocalSelectedItems([...localSelectedItems, option]);
    }
  };

  const filteredOptions = options.filter((option) => {
    const optionLabel = getOptionLabel(option).toLowerCase();
    return optionLabel.includes(searchTerm.toLowerCase());
  });

  // Get selected and total counts for display (based on parent state)
  const selectedCount = selectedItems.length;
  const totalCount = options.length;

  return (
    <div className="modal-multi-select-container">
      {label && <label className="modal-multi-select-label" htmlFor="modal-trigger">{label}</label>}
      <div
        id="modal-trigger"
        className="modal-multi-select-trigger"
        onClick={openModal}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            openModal();
          }
        }}
        role="button"
        tabIndex={0}
        aria-haspopup="dialog"
        aria-expanded={isOpen}
      >
        <div className="selected-display">
          {selectedItems.length > 0 ? (
            <span>
              {`${selectedCount} / ${totalCount} items selected`}
            </span>
          ) : (
            <span className="placeholder">{placeholder || "Select items..."}</span>
          )}
        </div>
        <span className="trigger-icon">▼</span>
      </div>

      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-container" ref={modalRef}>
            <div className="modal-header">
              <h3>{label || "Select Items"}</h3>
              <button
                type="button"
                className="close-button"
                onClick={closeModal}
                aria-label="Close"
              >
                ×
              </button>
            </div>

            <div className="modal-body">
              <div className="search-container">
                <input
                  type="text"
                  className="modal-search-input"
                  placeholder="Search options..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  ref={searchInputRef}
                />
              </div>

              <div className="options-list">
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option) => {
                    const value = getOptionValue(option);
                    const isSelected = localSelectedItems.some(
                      (item) => getOptionValue(item) === value,
                    );
                    return (
                      <div
                        key={value}
                        className={`option-item ${isSelected ? 'selected' : ''}`}
                        onClick={() => toggleOption(option)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            toggleOption(option);
                          }
                        }}
                        role="checkbox"
                        tabIndex={0}
                        aria-checked={isSelected}
                      >
                        <input
                          type="checkbox"
                          checked={isSelected}
                          onChange={() => {}}
                          aria-label={getOptionLabel(option)}
                        />
                        <span>{getOptionLabel(option)}</span>
                      </div>
                    );
                  })
                ) : (
                  <div className="no-results">No options found</div>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="cancel-button"
                onClick={cancelSelection}
              >
                Cancel
              </button>
              <button
                type="button"
                className="apply-button"
                onClick={applySelection}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalMultiSelect;
